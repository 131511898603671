import { Atom } from ":mods";
import { createLanguageTutorInputs } from "../../form";
import { FormPageProps } from "../../model";
import { TUTORS_FORM_STEPS } from "../../const";

export function LanguagePage({ onFormSubmit }: FormPageProps) {
  const FormInputs = createLanguageTutorInputs();
  const onSubmit = async () => {
    const values = FormInputs.Actions.getValuesSnakecase();
    onFormSubmit(values);
  };
  return (
    <section class="flex flex-col justify-center items-center bg#paper w-screen text-16px">
     <Atom.CircleProgress.CircleProgressbar steps={TUTORS_FORM_STEPS} current_step={4}/>
      <h1 class="text-28px font-medium my-40px ">Tell us about what language can you speak</h1>

      <form class="flex flex-col items-left gap-20px text-16px pt-40px w-630px">
        <div class="flex flex-col gap-10px">
          <FormInputs.SpeakArabic.Label title="Do you speak Arabic?" class="mb-10px">
            <FormInputs.SpeakArabic.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.SpeakArabic.Label>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.SpeakArabic.Input type="radio" class="" placeholder="" name="speak_arabic" id="true" />
            <p class="text-16px">Yes</p>
          </div>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.SpeakArabic.Input type="radio" class="" placeholder="" name="speak_arabic" id="false" />
            <p class="text-16px">No</p>
          </div>
        </div>
        
        <div class="flex flex-col gap-10px">
          <FormInputs.SpeakEnglish.Label title="Do you speak English?" class="mb-10px">
            <FormInputs.SpeakEnglish.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.SpeakEnglish.Label>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.SpeakEnglish.Input type="radio" class="" placeholder="" name="speak_english" id="true" />
            <p class="text-16px">Yes</p>
          </div>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.SpeakEnglish.Input type="radio" class="" placeholder="" name="speak_english" id="false" />
            <p class="text-16px">No</p>
          </div>
        </div>
        <div class="flex justify-end">
          <FormInputs.Actions.Button
            class="disabled:bg#disabled  flex items-center justify-center text-16px cursor-pointer bg#p w-88px h-44px text#n-50 mt-20px mb-80px px-30px py-20px"
            onclick={onSubmit}
            statusValid={"Next"}
            statusInvalid={"Next"}
          />
        </div>
      </form>
    </section>
  );
}
